import "../icon.styles.css";

const StyledIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Frame 1"
        clip-path="url(#clip0_1_2)">
        <path
          className="highlight"
          id="Vector 1"
          d="M-2.62163 137.473V123.892L51.7027 91.2973L57.1351 99.446L8.24324 132.041L59.8513 161.919L51.7027 172.784L-2.62163 137.473Z"
          fill="#DEF7FF"
        />
        <path
          className="highlight"
          id="Vector 2"
          d="M252.703 137.473V123.892L198.378 91.2973L192.946 99.446L241.838 132.041L190.23 161.919L198.378 172.784L252.703 137.473Z"
          fill="#DEF7FF"
        />
        <path
          id="Vector"
          className="shadow"
          d="M144.054 134.757C144.054 132.946 143.511 129.324 141.338 129.324L127.997 137.329C127.84 137.423 127.657 137.474 127.474 137.468C121.543 137.292 119.78 132.501 119.62 129.737C119.605 129.472 119.713 129.219 119.901 129.031L122.324 126.608C122.36 126.537 122.396 126.463 122.432 126.387L73.4324 167.351V175.5H95.1622L127.757 148.338L144.054 134.757Z"
          fill="#B9CCD2"
        />
        <path
          id="Vector_2"
          className="shadow"
          d="M97.8784 123.892C90.6351 128.419 75.6054 138.559 73.4324 142.905V167.351L122.432 126.387C123.055 125.083 123.773 123.06 123.44 121.957C123.235 121.28 122.609 120.461 122.855 119.797C123.012 119.374 123.174 118.926 123.336 118.459L106.311 131.561C106.127 131.702 105.897 131.777 105.666 131.763C99.4477 131.379 97.8784 126.387 97.8784 123.892Z"
          fill="#B9CCD2"
        />
        <path
          className="highlight"
          id="Vector_3"
          d="M149.486 148.338C150.829 146.323 153.168 142.516 154.287 139.797C154.888 138.336 155.404 136.781 156.161 135.394C156.833 134.163 157.567 132.647 158.23 130.965C157.123 133.453 154.7 134.976 152.203 137.473C150.03 139.646 145.865 140.189 144.054 140.189L146.77 137.473C148.943 135.3 149.489 132.772 149.491 131.78C148.584 131.867 146.227 132.584 144.054 134.757L127.757 148.338C127.757 151.959 129.386 158.659 135.905 156.486L149.486 148.338Z"
          fill="#DEF7FF"
        />
        <path
          id="Vector_4"
          className="shadow"
          d="M133.189 175.5L148.311 160.378C149.08 159.609 149.633 158.648 149.859 157.584C150.669 153.776 151.369 148.338 149.486 148.338L135.905 156.486C129.386 158.659 127.757 151.959 127.757 148.338L95.1622 175.5H133.189Z"
          fill="#B9CCD2"
        />
        <path
          className="highlight"
          id="Vector_5"
          d="M141.338 129.324C144.054 125.703 148.943 117.373 146.77 113.027C144.959 110.311 137.535 109.224 122.324 126.608L119.901 129.031C119.713 129.219 119.605 129.472 119.62 129.737C119.78 132.501 121.543 137.292 127.474 137.468C127.657 137.474 127.84 137.423 127.997 137.329L141.338 129.324Z"
          fill="#DEF7FF"
        />
        <path
          className="highlight"
          id="Vector_6"
          d="M125.041 104.878C124.135 103.973 121.238 103.249 116.892 107.595L97.8784 123.892C97.8784 126.387 99.4477 131.379 105.666 131.763C105.897 131.777 106.127 131.702 106.311 131.561L123.336 118.459C125.037 113.573 126.841 106.678 125.041 104.878Z"
          fill="#DEF7FF"
        />
        <path
          id="Vector_7"
          className="shadow"
          d="M160.351 121.176L168.5 91.2973H176.649V75H157.635L155.024 82.8324C154.956 83.0377 154.955 83.2587 155.026 83.463C155.999 86.262 158.259 91.2973 160.351 91.2973L154.919 118.459C153.144 121.122 149.629 127.438 149.491 131.78C149.489 132.772 148.943 135.3 146.77 137.473L144.054 140.189C145.865 140.189 150.03 139.646 152.203 137.473C154.7 134.976 157.123 133.453 158.23 130.965C158.318 130.742 158.405 130.515 158.49 130.287C159.539 127.474 160.351 124.264 160.351 121.176Z"
          fill="#B9CCD2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect
            width="250"
            height="250"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StyledIcon;
