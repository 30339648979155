import "../icon.styles.css";

const ReactIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1">
        <path
          className="highlight"
          id="Vector"
          d="M125 178C54.8913 178 0 154.46 0 124.5C0 94.54 54.8913 71 125 71C195.109 71 250 94.54 250 124.5C250 154.46 195.109 178 125 178ZM125 81.7C56.5217 81.7 10.8696 103.635 10.8696 124.5C10.8696 145.365 56.5217 167.3 125 167.3C193.478 167.3 239.13 145.365 239.13 124.5C239.13 103.635 193.478 81.7 125 81.7Z"
          fill="#DEF7FF"
        />
        <path
          className="highlight"
          id="Vector_2"
          d="M77.1462 236C71.7399 236 67.4149 234.916 63.0899 232.206C36.5992 217.03 43.6273 157.954 78.2275 97.2513C94.4463 69.0679 114.45 45.2205 133.912 30.0449C154.997 13.7853 173.919 8.90738 186.894 16.4952C200.409 24.083 205.275 43.0526 202.031 69.6099C198.787 94.5413 187.975 123.809 171.756 151.992C155.537 180.175 135.534 204.023 116.071 219.198C102.015 230.038 88.4994 236 77.1462 236ZM173.378 23.541C164.728 23.541 153.375 28.4189 140.94 38.1747C122.559 52.8083 103.637 75.5718 87.9588 102.671C53.8992 161.748 50.6555 212.695 68.4962 222.992C77.6868 228.412 92.8244 223.534 109.584 210.527C127.965 195.893 146.887 173.129 162.565 146.03C178.244 118.931 188.515 91.2894 191.759 67.984C194.462 46.3045 191.219 31.1288 182.028 25.709C179.325 24.625 176.622 23.541 173.378 23.541Z"
          fill="#DEF7FF"
        />
        <path
          id="Vector_3"
          className="highlight"
          d="M173.304 236C146.396 236 107.648 202.942 78.5866 151.458C43.6057 90.7616 36.6096 31.6908 62.9798 16.5166C89.35 1.34244 136.709 36.5682 171.69 97.2648C187.835 125.445 198.598 154.71 201.827 179.639C205.594 206.194 200.213 224.619 186.758 232.748C182.453 234.916 178.148 236 173.304 236ZM68.3615 25.7295C50.6019 36.0262 53.8309 86.9681 87.7355 146.039C121.64 205.11 163.617 233.29 181.377 222.994C190.526 217.574 193.755 202.4 191.064 180.723C187.835 157.42 177.609 129.781 162.003 102.684C128.098 43.6133 86.121 15.4327 68.3615 25.7295Z"
          fill="#DEF7FF"
        />
        <path
          className="shadow"
          id="shadow"
          d="M125 146C137.15 146 147 136.374 147 124.5C147 112.626 137.15 103 125 103C112.85 103 103 112.626 103 124.5C103 136.374 112.85 146 125 146Z"
          fill="#B9CCD2"
        />
      </g>
    </svg>
  );
};

export default ReactIcon;
