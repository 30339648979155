import "../icon.styles.css";

const GitIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Frame 1"
        clip-path="url(#clip0_1_2)">
        <path
          className="highlight"
          d="M245.286 113.863L136.133 4.71469C129.851 -1.57156 119.655 -1.57156 113.365 4.71469L90.6994 27.3811L119.452 56.1346C126.134 53.8767 133.796 55.3914 139.122 60.7177C144.474 66.0781 145.978 73.8058 143.665 80.5091L171.375 108.221C178.079 105.911 185.815 107.405 191.169 112.768C198.653 120.249 198.653 132.373 191.169 139.859C183.684 147.345 171.56 147.345 164.071 139.859C158.442 134.224 157.051 125.957 159.901 119.022L134.059 93.1802L134.056 161.186C135.929 162.113 137.64 163.336 139.122 164.809C146.606 172.29 146.606 184.415 139.122 191.907C131.637 199.389 119.507 199.389 112.032 191.907C104.549 184.415 104.549 172.291 112.032 164.81C113.826 163.016 115.957 161.595 118.303 160.63V91.9897C115.954 91.0307 113.821 89.6089 112.032 87.809C106.363 82.1468 104.999 73.8273 107.905 66.8653L79.5607 38.518L4.71681 113.358C-1.57227 119.65 -1.57227 129.847 4.71681 136.134L113.871 245.285C120.155 251.572 130.349 251.572 136.641 245.285L245.285 136.641C251.572 130.352 251.572 120.149 245.285 113.863"
          fill="#DEF7FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect
            width="250"
            height="250"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GitIcon;
