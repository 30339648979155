import "../icon.styles.css";

const NodeIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1">
        <path
          className="highlight"
          id="icon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M239.467 67.8646L125.5 1.38403L11.5333 67.8646V183.136L29.1778 193.428L65.2316 178.84C75.1655 174.821 81.6674 165.176 81.6674 154.46V64.1333H99.2007V154.46C99.2007 172.321 88.3644 188.394 71.808 195.093L48.3228 204.596L125.5 249.615L239.467 183.136V67.8646ZM116.733 99.2C116.733 79.8332 132.433 64.1333 151.8 64.1333H172.256C190.009 64.1333 204.4 78.5249 204.4 96.2777V99.2H186.867V96.2777C186.867 88.2083 180.325 81.6667 172.256 81.6667H151.8C142.117 81.6667 134.267 89.5167 134.267 99.2C134.267 108.883 142.117 116.733 151.8 116.733H169.333C188.701 116.733 204.4 132.433 204.4 151.8C204.4 171.167 188.701 186.867 169.333 186.867H151.8C132.433 186.867 116.733 171.167 116.733 151.8H134.267C134.267 161.483 142.117 169.333 151.8 169.333H169.333C179.017 169.333 186.867 161.483 186.867 151.8C186.867 142.117 179.017 134.267 169.333 134.267H151.8C132.433 134.267 116.733 118.567 116.733 99.2Z"
          fill="#DEF7FF"
        />
      </g>
    </svg>
  );
};

export default NodeIcon;
